<template>
  <div>
    <comment-sidebar />
    <h1>Comentários</h1>
    <div class="d-flex align-items-center justify-content-end m-2">
      <b-button variant="primary" @click="addComment">
        <feather-icon icon="PlusCircleIcon" class="mr-50" />
        <span class="text-nowrap">Novo comentário</span>
      </b-button>
    </div>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="items.length > 0">
      <b-card no-body class="mb-0 m-2">
        <b-table
          :items="items"
          striped
          borderless
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="Não foram encontrados comentários"
          :busy="loading"
        >
          <!-- Column: Date -->
          <template #cell(date)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.created_at | dateTime
              }}</span>
            </div>
          </template>
          <!-- Column: Comment -->
          <template #cell(comment)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.comment
              }}</span>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(user)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.user.name
              }}</span>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              right
              v-if="data.item.hasOwnership"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="remove(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Remover</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </template>
    <template v-else>
      <span class="d-flex justify-content-center">
        Não foram encontrados comentários
      </span>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { avatarText } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import CommentSidebar from "@/modules/shared/components/CommentSidebar";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    CommentSidebar,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "date", label: "Criado" },
        { key: "comment", label: "Comentário" },
        { key: "user", label: "Responsável" },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      comments: types.COMMENTS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.comments || [];
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getComments: types.GET_COMMENTS,
      deleteComment: types.DELETE_COMMENT,
      openCommentSidebar: sharedTypes.OPEN_COMMENT_SIDEBAR,
      saveComment: types.SAVE_COMMENT,
    }),
    addComment(contractId) {
      this.openCommentSidebar({
        id: this.$route.params.id,
        title: "Adicionar comentário ao contrato " + this.$route.params.id,
        saveAction: this.save,
      });
    },
    async save(payload) {
      await this.saveComment(payload).then((resp) => {
        this.get();
        return resp;
      });
    },
    get() {
      this.loading = true;
      this.getComments({
        id: this.$route.params.id,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os comentários. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(idComment) {
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja remover o comentário?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deleteComment(idComment)
            .then((response) => {
              this.get();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `O comentário foi removido`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao remover o comentário. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
